.chatbot {
    display: grid;
    grid-template-columns: 3fr 1fr; /* Two columns of equal size */
    height: 100vh /* Full viewport height */
}
/* Media query for smaller screens */
@media (max-width: 600px) {
    .chatbot {
      grid-template-columns: none;
      grid-template-rows: 2fr 1fr; /* Two rows of equal size */
    }
}
.interviewv2, .recorder-contentv2 {
    display: flex;
    flex-direction: column;
    height: cal(100vh-10px);
    /* padding: 10px; */
    background-color: white;
    box-shadow: 0 0px 0px rgba(0,0,0,0.1);
    overflow-y: auto; 
    max-height: 600px;
    /* border-radius: 8px; */
    /* background-color: #D9D9D9; */
    /* bottom: auto; */
    margin: 20px;
    text-align: center;
    align-items: center;
    /* padding: 10px; */
}
/* .instructions {
    font-size: 12px;
    padding-left: 20px;
    max-height: 2vh;
} */

.header {
    display: flex;
    flex-direction: row;
    height: 60px;
}
.instructions {
    padding: 10px;
    background-color: #DEE5F2; /* Light grey background */
    font-size: 11px;
    flex: 1;
    overflow-y: auto;
    height: 31.5vh;
    margin-bottom: 10px;
    border-radius: 12px;
  }
  
  .instructions h4 {
    margin-top: 0;
    margin-bottom: 0;
  }
  
  ol {
    padding-left: 20px;
  }
  
  .camera {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: #bbb;
    border-radius: 12px;
    height: 20vh;
    overflow: hidden;
  }