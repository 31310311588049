/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(20px + 2vmin);
  color: white;
} */
.App {
  background-color: #282c34;
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(5px + 2vmin);
  margin: 0;
}
.Appv2 {
  background-color: #fff;
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(6px + 2vmin);
  margin: 0px;
}
.textv2 {
  /* background-image: linear-gradient(to bottom, rgba(66, 120, 223, 0.8), rgba(110, 201, 214, 0.5)); */
  color: #4278DF;
  margin-top: -20px;
}
.recordcontainer {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 5px;
}
.webcam {
  position: relative;
  top: 1%;
  left: 1%;
  height: 100px;
  width: 160px;
}
.screenrecorder {
  position: relative;
  top: 1%;
  right: 1%;
  height: 100px;
  width: 160px;
}
.recordbuttonstart {
  position: relative;
  top: auto;
  left: auto;
  padding: 10px 20px;
  font-size: 15px;
  cursor: pointer;
  border: none;
  border-radius: 20px;
  color: #FFF;
  background-color: #007BFF;
  outline: none;
  margin: 20px;
}
.recordbuttonstop {
  position: absolute;
  top: 1%;
  right: 25%;
  padding: 10px 20px;
  font-size: 15px;
  cursor: pointer;
  border: none;
  border-radius: 20px;
  color: #FFF;
  background-color: #007BFF;
  outline: none;
  margin: 20px;
}
@keyframes blip {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}
.blip {
  animation: blip 1s infinite;
}
.chat-container {
  background-color: #282c34;
  border-radius: 8px;
  padding: 20px;
  width: 80%;  /* Adjust width as needed */
  margin: 10px auto;
  box-shadow: 0 2px 10px rgba(0,0,0,1);
  overflow: hidden;  /* Keeps everything within the rounded corners */
  text-align: left;  /* Aligns text to the left */
  display: flex;
  flex-direction: column; 

}
.Top-half, .Bottom-half {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  flex-direction: column;
  color:Black;
  background-color: transparent;  /* Dark background for visual contrast */
}
.Top-half {
  /*background-color: #282c34;   Dark background for visual contrast */
  margin-top: -5vh;
  flex-direction: column;
  /* overflow-y: auto; */
  margin-bottom: 0px;
}
.Bottom-half {  /* Light background for visual contrast */
  flex-direction: column;
  background-color: transparent;
  margin-bottom: 5vh;
  /* margin-top: -1vh; */
}


.button {
  padding: 10px 10px;
  font-size: 30px;
  cursor: pointer;
  border: none;
  border-radius: 10000px;
  color: #FFF;
  background-color: #007BFF;
  outline: none;
  margin: 5px;
}
.rippling {
  animation: ripple_voice 2s infinite;
}

@keyframes ripple_voice {
  0% {
      box-shadow: 0 0 0 0 #FFFFFF;
  }
  50% {
      box-shadow: 0 0 0 30px #75899e;
  }
  100% {
      box-shadow: 0 0 0 0 #75899e;
  }
}

.circle_ripple_1 {
  height: 200px;
  width: 200px;
  background: #36B37E;
  border-radius: 50%;
  animation: ripple 2s infinite;
  position: absolute;
  /* left: 50% ;
  top: 50%;
  z-index: +1; */
  bottom: -100px;
  left: 40%;
}
.circle_ripple_2 {
  height: 200px;
  width: 200px;
  background: #0077B6;
  border-radius: 50%;
  animation: ripple 2s infinite;
  position: absolute;
  bottom: -100px;
  left: 40%;
}

@keyframes ripple {
  0% {
      transform: scale(1);
      opacity: 1;
  }
  50% {
      transform: scale(2);
      opacity: 0.3;
  }
  100% {
      transform: scale(1);
      opacity: 0;
  }
}

.blob {
  width: calc(60px + 2vmin);
  height: calc(60px + 2vmin);
  color: #000;
  top: 10px;
}
.mic {
  width:40px;
  height:40px;
  color:#fff;
  cursor: pointer;
  padding: 30px;
  /* border: none; */
  border-radius: 50%;
  /* color: #FFF; */
  background-color: #000;
  /* outline: auto; */
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.1);
  
}

/* Form styling */
/* Overall form container styling */
.form-container {
  max-width: 600px;
  margin: 0px auto;
  padding: 0px;
  font-size: 12px;
  background: #ffffff;
  box-shadow: 0 0 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: left;
}

/* Style for form inputs and button */
.input-field, .submit-button, .file-input {
  width: calc(100% - 20px); /* 100%; */
  padding: 10px 15px;
  margin-bottom: 10px;
  background-color: #F6F6F6;
  border: 1.5px solid #ddd;
  border-radius: 100px;
  box-sizing: border-box; /* Includes padding and border in the element's total width and height */
  transition: border-color 0.3s;
  margin: 10px;
}

.input-field:focus, .submit-button:focus {
  border-color: #0056b3;
  outline: none; /* Removes the default focus outline */
}
.form-text {
  color: #787879;
  margin: 10px;
}

/* Style for the submit button */
.submit-button {
  /* background-color: #007bff; */
  background: linear-gradient(to right, #6EC9D6, #4278DF);
  color: white;
  font-size: 12px;
  cursor: pointer;
  border: none; /* Removes border */
  /* margin-top: 20px; */
}

.submit-button:hover {
  background-color: #0056b3;
}

/* Styling for file input */
.file-input {
  border: 1.5px solid #4278DF;
  background-color: #fff;
  border-radius: 100px;
  /* padding: 10px 15px; */
  /* text-align: center; */
  font-size: 16px;
  /* margin-bottom: 10px; */
  vertical-align: middle;
  text-align: center;
}

.file-input input[type="file"] {
  display: none; /* Hide the default file input */
}

.file-input label {
  cursor: pointer;
  color: #007bff;
}

.file-input:hover {
  border-color: #0056b3;
}
.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px; /* Space between the two columns */
  
}

/*dropdown for instructions*/
.dropdown-container {
  text-align: center;
  margin-top: 1px;
  width: 100%;
  color: #FFF;
}

.dropdown-toggle {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.chat-container ul {
  list-style-type: none;
  padding: 0;
  font-size: large;
  flex: 1;
}

.chat-container li {
  padding: 2px;
  border-bottom: 1px solid #ddd;
}

.chat-container li:last-child {
  border-bottom: none;
}

.chat-container li:hover {
  background-color: #0056b3;
}